import axios from "axios";
import React, { Component } from "react";
import PageBanner from "../../components/service/PageBanner";
import TeamPage from "../../components/team/TeamPage";

import { Helmet } from "react-helmet";

var Api_url = process.env.REACT_APP_API_URL;
var url = Api_url + `/api/governing-board/members-lists`;
console.log('API_URL=' + Api_url + ', url=' + url);

const axiosConfig = {
    headers: {
        "Content-Type": "application/json; text/html; charset=UTF-8",
        "Access-Control-Allow-Origin": "*"
    }
};

export default class NomCoordinators extends Component {
    constructor() {
        super();
        this.state = {
            members: [],
            LoadingFlag: false,
            TotalRecords: 0,
        };
    }
    async getAllRecords() {
        console.log("fetchData called ");
        var url = Api_url + `/api/website/board-members`;
        console.log("url=" + url);
        var postData = {
            UserInterface: 8,
            OrganizationTypeId: 1,
            CommitteeId: 23,
        };
        console.log("postData=" + JSON.stringify(postData));
        this.setState({ LoadingFlag: true });
        await axios
            .post(url, postData, axiosConfig)
            .then(res => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));
                var result = res.data.records;
                var n1 = result.length;
                console.log("n1=" + n1);
                console.log("result=" + JSON.stringify(result));
                this.setState({ LoadingFlag: false, TotalRecords: n1, members: result });
            })
            .catch(err => {
                console.log("AXIOS ERROR: ", err);
            });
    };

    componentDidMount() {
        console.log("componentDidMount called ");
        window.scrollTo(0, 0);
        this.getAllRecords();
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>NOM Coordinators | JCI India | Be Better</title>
                    {/* <meta name='description' content='About-JCI India' /> */}
                </Helmet>
                <PageBanner bannerDetails={{ bannerTitle: "Nom Coordinators", bannerActive: "Team" }} />
                {this.state.TotalRecords > 0 ? <TeamPage members={this.state.members} /> : <p className="text-center">No Records Found<br/><br/></p>}
            </>
        );
    }
}
