import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import PageBanner from "../../components/service/PageBanner";
import TeamPage from "../../components/team/TeamPage";
import LoadingContent from "../../components/LoadingContent";

import Select from "react-select";
import axios from "axios";
import { Helmet } from "react-helmet";

var Api_url = process.env.REACT_APP_API_URL;
console.log("API_URL=" + Api_url);

const axiosConfig = {
    headers: {
        "Content-Type": "application/json; text/html; charset=UTF-8",
        "Access-Control-Allow-Origin": "*"
    }
};

export default class NationalCommittees extends Component {
    constructor() {
        super();
        this.state = {
            members: [],
            isClearable1: true,
            isDisabled1: false,
            isLoading1: false,
            isSearchable1: true,
            CommitteeOptions: [],
            CommitteeId: 2,
            CommitteeName: "ADMINISTRATIVE COMMITTEE",
            LoadingFlag: false,
            TotalRecords: 0,
        };
    }

    async fetchData(e) {
        console.log("fetchData called ");
        var CommitteeId = e.value;
        this.setState({ CommitteeId: e.value, CommitteeName: e.label });
        this.setState({ LoadingFlag: true, members:[] });
        var url = Api_url + `/api/website/board-members`;
        console.log("url=" + url + ", CommitteeId=" + CommitteeId);
        var postData = {
            UserInterface: 7,
            OrganizationTypeId: 1,
            CommitteeId: CommitteeId,
        };
        console.log("postData=" + JSON.stringify(postData));
        await axios
            .post(url, postData, axiosConfig)
            .then(res => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));
                var result = res.data.records;
                var n1 = result.length;
                console.log("n1=" + n1);
                console.log("result=" + JSON.stringify(result));
                var TeamName = res.data.team_name;
                this.setState({ LoadingFlag: false, TotalRecords: n1, members: result, TeamName: TeamName });
            })
            .catch(err => {
                console.log("AXIOS ERROR: ", err);
            });
    };

    async getTeams() {
        console.log("getTeams called ");
        var url = Api_url + `/api/app/committee/options`;
        console.log("url=" + url);
        var postData = {
            additional: {
                UserInterface: 1,
            }
        };
        console.log("postData=" + JSON.stringify(postData));
        this.setState({ isLoading1: true });
        await axios
            .post(url, postData, axiosConfig)
            .then(res => {
                console.log("RESPONSE RECEIVED: ", res);
                console.log("res=" + JSON.stringify(res));
                var result = res.data.records;
                console.log("result=" + JSON.stringify(result));
                var options = result.map(d => ({
                    value: d.value,
                    label: d.text
                }));
                console.log("options=" + JSON.stringify(options));
                this.setState({ CommitteeOptions: options });
                this.setState({ isLoading1: false });
                this.fetchData({ value: this.state.CommitteeId, label: this.state.CommitteeName });
            })
            .catch(err => {
                console.log("AXIOS ERROR: ", err);
            });
    };

    componentDidMount() {
        console.log("componentDidMount called ");
        window.scrollTo(0, 0);
        this.getTeams();
    }
    render() {
        return (
            <>
                <Helmet>
                    <title>National Committees | JCI India | Be Better</title>
                    {/* <meta name='description' content='About-JCI India' /> */}
                </Helmet>
                <PageBanner bannerDetails={{ bannerTitle: "National Committees", bannerActive: "Team" }} />
                <Container>
                    <br /><br /><br />
                    <Row className=" align-items-center">
                        <Col md={6} lg={6} >
                            Select Committe
                        </Col>
                        <Col md={6} lg={6} style={{ zIndex: 10 }}>
                            <Select
                                options={this.state.CommitteeOptions}
                                onChange={this.fetchData.bind(this)}
                                isClearable={this.state.isClearable1}
                                isSearchable={this.state.isSearchable1}
                                isDisabled={this.state.isDisabled1}
                                isLoading={this.state.isLoading1}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center mt-5"> <h3> {this.state.CommitteeName} </h3> </Col>
                    </Row>
                </Container>
                {this.state.TotalRecords > 0 ? <TeamPage members={this.state.members} /> : <p className="text-center">No Records Found<br/><br/></p>}
                {this.state.LoadingFlag ? <LoadingContent /> : ''}
            </>
        );
    }
}